import React from "react"
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import SoftwareDownloadButton from "../../../../components/SoftwareDownload/SoftwareDownloadButton";
import {LocaleContext} from "../../../../components/layout";

const Kioku62j = () => {
    const {locale} = React.useContext(LocaleContext)
    const Kioku62jJa = () => {
        return(
            <div className="content-container">
                <h2 className={'large bold'}>記憶計 SK-L200Ⅱシリーズ用 ソフトウェアダウンロード</h2>
                <div className="software-content">
                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>このドキュメントは本製品に関する重要な情報について書かれています。<br/>
                                ご使用の前に、必ずご一読くださるようお願いいたします。</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="content-title">
                            <p>＜利用規約＞</p>
                        </div>
                        <div className="content-disclaimer">
                            <div className="content-disclaimer-title">
                                <p> ■免責事項</p>
                            </div>
                            <div className="content-disclaimer-content quote">
                                <p>
                                    株式会社佐藤計量器製作所は本製品に関して動作確認はおこなっておりますが、全ての状況下において動作を保証しているわけではありません。<br/>
                                    本製品により生じた損害は、直接的および間接的損害を問わず、弊社に一切の賠償責任はないものとします。<br/>
                                    操作上のミス、注意を無視した操作、ご使用になられているＰＣに起因する不具合に対して、弊社は責任を負いかねますのでご了承ください。<br/><br/>
                                    ※こちらで提供いたしております各種ソフトウェアは、日本国内で販売されている各製品と日本語版OSの組み合わせのみ、動作確認をおこなっております。<br/>
                                    海外版OSとの組み合わせにて発生したトラブルに関しましては、一切保証およびサポートいたしかねますのでご了承ください。
                                </p>
                            </div>
                        </div>
                        <div className="content-copyright">
                            <div className="content-copyright-title">
                                <p> ■著作権</p>
                            </div>
                            <div className="content-copyright-content">
                                <ol>
                                    <li>
                                        「記憶計 for Windows」のプログラムおよび関連ドキュメントに関する著作権は株式会社佐藤計量器製作所に帰属します。
                                    </li><br/>
                                    <li>
                                        「記憶計 for Windows」は以下条件をもとに、無償でご利用いただけます。
                                        <ul>
                                            <li>
                                                <p>逆コンパイル・ファイル解析、改変等を禁じます。</p>
                                            </li>
                                            <li>
                                                <p>本プログラムの使用による損害は、弊社は一切の責任を負いません。</p>
                                            </li>
                                            <li>
                                                <p>再配布は自由としますが、企業内・企業間、その他関係においても営利目的にしないこと。<br/>
                                                    再配布したプログラムの使用による損害に対しても弊社は一切の責任を負いません。</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                                <p className="quote">本利用規約に同意いただけた方にのみ、以下ソフトウェアをご利用いただけます。</p>
                            </div>
                        </div>
                        <div className="content-feature">
                            <div className="content-feature-title">
                                <p> ■ソフトウェアの特徴</p>
                            </div>
                            <div className="content-feature-content">
                                <p>
                                    「記憶計 for Windows」は、記憶計「SK-L200Ⅱシリーズ」本体の各設定、データの解析をおこなうものです。記憶計で測定したデータは、 「記憶計 for Windows」を用いてパソコンへ回収し、グラフ表示・印刷およびデータ解析がおこなえます。
                                    <br/>
                                    また、データをCSV形式のファイルとして保存した場合は、市販のCSV形式対応の表計算ソフトでデータ解析が可能です。
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="way-to-install">
                        <div className="install-title">
                            <p className="bold">【インストール方法】</p>
                        </div>
                        <div className="install-content">
                            <p>
                                「<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/記憶計ソフトウェアインストールマニュアル_S06.pdf" target={"_blank"} rel="noreferrer">記憶計ソフトウェアインストールマニュアル.pdf</a>」に従ってインストールをおこなってください。                                     </p>
                            <span className="important-text">(インストール前に必ずお読みください。)</span>
                            <p>
                                ご注意：
                                <br/>
                                旧バージョンのソフトウェアがインストール済みの場合、ダウンロードしたソフトウェアをインストールする前に、必ず旧バージョンのアンインストールをおこなってください。
                            </p>
                        </div>
                    </div>
                    <div className="kioku62j-note">
                        <div className="kioku62j-note-title">
                            <p className="bold">【リアルタイム測定中の注意事項】</p>
                        </div>
                        <div className="kioku62j-note-content">
                            <p>
                                正しく測定をおこなうため、リアルタイム測定中は下記の項目をお守りください。
                            </p>
                            <ol>
                                <li>
                                    <p>スクリーンセーバー起動の禁止</p>
                                </li>
                                <li>
                                    <p>他ソフトウェア起動の禁止</p>
                                </li>
                                <li>
                                    <p>ディスプレイ等の省電力モードの禁止</p>
                                </li>
                                <li>
                                    <p>常駐ソフトウェアの禁止</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">【ダウンロード】</p>
                        </div>
                        <div className="download-pre">
                            <p>※ご使用されるパソコンのOSバージョンおよび、bit数をご確認の上、対応するソフトウェアをダウンロードしてください。</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">ソフトウェア名</p>
                                    </th>
                                    <td>
                                        <p>記憶計 for Windows Ver.6.2J　　　　　（2024年5月20日掲載）</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">対象機器</p>
                                    </th>
                                    <td>
                                        <p>
                                            SK-L200T II
                                            <br/>
                                            SK-L200TH II
                                            <br/>
                                            SK-L200TH IIα
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">対象OS</p>
                                    </th>
                                    <td>
                                        <p>
                                            Windows10 （64bit版/32bit版）<br/>
                                            Windows8.1（64bit版/32bit版）<br/>
                                            Windows8　（64bit版/32bit版）<br/>
                                            Windows7　（64bit版/32bit版、SP3以上）<br/>
                                        </p>
                                        <p>
                                            ※Windows11での動作について<br/>
                                            　正常に動作することを確認しました。<br/>
                                            　ただし、お客様の使用環境や今後Microsoft社がおこなうWindows Updateなどにより<br/>
                                            　動作が不安定になる可能性があります。
                                        </p>
                                        <p>
                                            ※Windows7、8、8.1について<br/>
                                            　上記OSはMicrosoft社のOSサポートが終了しています。<br/>
                                            　ソフトウェアは引き続きご利用いただけますが、上記OS上での動作や不具合などについての<br/>
                                            　お問い合わせには回答いたしかねますのでご了承ください。
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">ダウンロード</p>
                                    </th>
                                    <td>
                                        <p>ご注意</p>
                                        <ol>
                                            <li>
                                                <p>以下の本体シリアルナンバーの機器から、USBドライバが変更になります。</p>
                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <p>SK-L200T II　　：No.030051～</p>
                                                    </li>
                                                    <li>
                                                        <p>SK-L200T IID 　：No.000651～</p>
                                                    </li>
                                                    <li>
                                                        <p>SK-L200TH IIα  ：No.045701～</p>
                                                    </li>
                                                    <li>
                                                        <p>SK-L200TH IIαD ：No.007601～</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                <p>
                                                    ご使用される機器の本体シリアルナンバーをご確認いただき、対応するソフトウェアを以下より選択して
                                                    ダウンロードしてください。
                                                    <br/>
                                                    なお、以前より本製品をご使用であり、既にソフトウェアがインストールされている場合は、対応する
                                                    ソフトウェアをダウンロードし、「USBドライバ」のみインストールしてください。
                                                </p>
                                                <p className="important-text">
                                                    ※インストール済みのUSBドライバ「SK-L200 SERIS Serial Port Drivers」は、以前からご使用の機器に
                                                    必要ですので、削除しないでください。
                                                </p>
                                            </li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">本体シリアル <br/>ナンバーが <br/>上記対象番号の <br/>場合</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey64bit="corporate/software/kioku62J_64n.zip"
                                            tmpKey64bit="software/kioku62J_64n.zip"
                                            yourKey32bit="corporate/software/kioku62J_32n.zip"
                                            tmpKey32bit="software/kioku62J_32n.zip"
                                        />
                                        <p>※ご使用のウェブブラウザーによっては、ダウンロードが正しくおこなわれない場合があります。<br/>
                                        　その場合は下記をご参照の上、ダウンロードをおこなってください。<br/>
                                        　　Microsoft Edge：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Edgeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Edgeご使用時の補足.pdf」</a><br/>
                                        　　Google Chrome ：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Chromeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Chromeご使用時の補足.pdf」</a>
                                        </p>                        
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">本体シリアル <br/>ナンバーが <br/>上記対象番号 <br/>以外の場合</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey64bit="corporate/software/kioku62J_64.zip"
                                            tmpKey64bit="software/kioku62J_64.zip"
                                            yourKey32bit="corporate/software/kioku62J_32.zip"
                                            tmpKey32bit="software/kioku62J_32.zip"
                                        />
                                        <p>※ご使用のウェブブラウザーによっては、ダウンロードが正しくおこなわれない場合があります。<br/>
                                        　その場合は下記をご参照の上、ダウンロードをおこなってください。<br/>
                                        　　Microsoft Edge：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Edgeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Edgeご使用時の補足.pdf」</a><br/>
                                        　　Google Chrome ：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Chromeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Chromeご使用時の補足.pdf」</a>
                                        </p>                        
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">バージョンアップ内容</p>
                                    </th>
                                    <td>
                                        <p>Windows 8、Windows 8.1、Windows 10に対応しました。</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const Kioku62jEn = () => {
        return(
            <div className="content-container">
                <div className="software-content">
                    <h2 className={'large bold'}>Software for SK-L200 and SK-L200II Series Dataloggers</h2>

                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>This document describes important information about this product.<br/>
                                Read it thoroughly before use.</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="content-disclaimer">
                            <div className="content-disclaimer-title">
                                <p className="bold">Software Use Agreements</p>
                            </div>
                            <div className="content-disclaimer-content">
                                <p>
                                    This software shall be used only by users who have agreed on the following terms:
                                </p>
                                <p className="bold">Exemption from liability</p>
                                <p>
                                    We, Sato Keiryoki Mfg. Co., Ltd., check for general operation performance of the program, however we do not guarantee for those under all possible situations.<br/>
                                    We do not take any responsibilities on any damages caused directly or indirectly by this program.<br/>
                                </p>
                                <p>
                                    NB. The operation of our software has been checked by a combination of the Japanese edition OS and the device sold in Japan. We shall not guarantee nor support the problems that occurred during the usage of device and/or OS of overseas model (edition).
                                </p>
                            </div>
                        </div>
                        <div className="content-copyright">
                            <div className="content-copyright-title">
                                <p className="bold">Copyright</p>
                            </div>
                            <div className="content-copyright-content">
                                <ol>
                                    <li>
                                        All rights related to the program itself and documentations of the "DATALOGGER for Windows" are reserved by Sato Keiryoki Mfg. Co., Ltd.
                                    </li><br/>
                                    <li>
                                        The "DATALOGGER for Windows" can be used at free of charge under following limitations:
                                        <ul>
                                            <li>
                                                <p>Any decompilation, file analysis or alteration is prohibited.</p>
                                            </li>
                                            <li>
                                                <p>We shall not be responsible for any damage resulting from the use of this program.</p>
                                            </li>
                                            <li>
                                                <p>The responsibilities related to redistribution of the program are entirely onto users; in case of redistribution, the program shall not be used for commercial reasons even when used in-corporation, inter-corporation or by others.<br/>
                                                    We shall not be responsible for any damage resulting from the use of the redistributed program.</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="content-feature">
                            <div className="content-feature-title">
                                <p className="bold">Features of the software</p>
                            </div>
                            <div className="content-feature-content">
                                <ol>
                                    <li>
                                        <p>
                                            "DATALOGGER for Windows" is used to set the various settings in the SK-L200 series and SK-L200II series Dataloggers and analyze the acquired data.
                                            <br/>
                                            The data measured by the Datalogger can be transferred to a personal computer using "DATALOGGER for Windows" and then displayed as graphs, printed, or analyzed.
                                            <br/>
                                            By saving the data as a file in CSV format, it can be analyzed using commercially available CSV-compatible spreadsheet software.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="way-to-install">
                        <div className="install-title">
                            <p className="bold">Installation Method</p>
                        </div>
                        <div className="install-content">
                            <p>
                                Install "DATALOGGER for Windows Ver. 6.2E" following the instruction as described in "<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Software_Installation_Manual_S06.pdf" target={"_blank"} rel="noreferrer">Software_Installation_Manual_S06.pdf</a>".
                            </p>
                            <p className="important-text">(Be sure to read before installation)</p>
                            <p className="bold">
                                Caution:If an older version of the software is already installed, you must uninstall it before installing this program.
                            </p>
                        </div>
                    </div>
                    <div className="kioku62j-note">
                        <div className="kioku62j-note-title">
                            <p className="bold">To ensure correct realtime measurement, observe the following precautions:</p>
                        </div>
                        <div className="kioku62j-note-content">
                            <ol>
                                <li>
                                    <p>Do not activate a screen saver.</p>
                                </li>
                                <li>
                                    <p>Do not activate other software.</p>
                                </li>
                                <li>
                                    <p>Do not use the power saving functions for the display unit and other devices.</p>
                                </li>
                                <li>
                                    <p>Do not use memory-resident software.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">Software Download Site</p>
                        </div>
                        <div className="download-pre">
                            <p>* Be sure to check the OS version and the bit number of your computer before starting the installation and download the applicable software.</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Program Name</p>
                                    </th>
                                    <td>
                                        <p>DATALOGGER for Windows Ver. 6.2E</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Model</p>
                                    </th>
                                    <td>
                                        <p>
                                            SK-L200T II
                                            <br/>
                                            SK-L200TH II
                                            <br/>
                                            SK-L200TH IIα
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">OS</p>
                                    </th>
                                    <td>
                                        <p>
                                            Windows7(SP1 or better)                                            <br/>
                                            <br/>
                                            Windows 8
                                            <br/>
                                            Windows 8.1
                                            <br/>
                                            Windows 10
                                        </p>
                                        <p>
                                            *Operation verification has been done by Professional 64-bit and 32-bit editions.                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Software Download</p>
                                    </th>
                                    <td>
                                        <p className="important-text">The USB Driver has been upgraded for the units having the following serial numbers.</p>
                                        <ol>
                                            <li>
                                                <p>SK-L200T II : No.010851 or later</p>
                                            </li>
                                            <li>
                                                <p>SK-L200TH IIα : No.008201 or later</p>
                                            </li>
                                        </ol>
                                        <p>
                                            Check the serial number of the unit to be used and select the applicable software from the following.
                                            <br/>
                                            When you have used our dataloggers before and the software have been installed, download applicable software and install the "USB Driver".
                                            <br/>
                                            <span className="important-text">*Please do not remove the previous installed USB driver "SK-L200 Seral Port Drivers" because it is needed to use the previous unit.</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Download<br/>upgraded version</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey64bit="corporate/software/64bitkioku62E_64n.zip"
                                            tmpKey64bit="software/64bitkioku62E_64n.zip"
                                            yourKey32bit="corporate/software/32bitkioku62E_32n.zip"
                                            tmpKey32bit="software/32bitkioku62E_32n.zip"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Download<br/>non-upgraded version</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey64bit="corporate/software/64bitkioku62E_64.zip"
                                            tmpKey64bit="software/64bitkioku62E_64.zip"
                                            yourKey32bit="corporate/software/32bitkioku62E_32.zip"
                                            tmpKey32bit="software/32bitkioku62E_32.zip"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Version upgrade content</p>
                                    </th>
                                    <td>
                                        <p>Corresponds to Windows8, Windows8.1 and Windows10</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    {(locale === 'ja')?
                        <Kioku62jJa/>:
                        <Kioku62jEn/>
                    }
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default Kioku62j
